/*----------------------
base background and text
----------------------*/

/* status columns */
.column>.scrollable,
.status,
    .status__content,
.detailed-status,
    .detailed-status__action-bar,
    .focusable:focus .detailed-status,
    .focusable:focus .detailed-status__action-bar,
.setting-text,
    .setting-text:active,
    .setting-text:focus,
.status-direct .status__content .status__content__spoiler-link,
.column-link,
    .getting-started a.column-link,
    .getting-started__trends .trends__item__current,
.account__header__content,
.account__header__bio .account__header__content,
.account--panel,
.account__header__bar,
.account__header__account-note textarea,
.follow_requests-unlocked_explanation,
.account-authorize,
.trends__header,
/* new conversations */
.conversation--unread,
.conversation__content__names,
/* search */
.search__input,
    .search__input:focus,
.search-results .account,
.trends__item,
    .trends__item__name a,
    .trends__item__current,
/* compose form */
.reply-indicator__content, /* in reply to */
.compose-form .spoiler-input__input, /* cw */
.compose-form .autosuggest-textarea__textarea, /* post */
.compose-form .compose-form__modifiers, /* image uploads */
.compose-form .compose-form__buttons-wrapper, /* buttons */
/* settings page */
.simple_form textarea,
    .simple_form textarea:active,
    .simple_form textarea:focus,
.simple_form input[type=email],
    .simple_form input[type=email]:active,
    .simple_form input[type=email]:focus,
.simple_form input[type=number],
    .simple_form input[type=number]:active,
    .simple_form input[type=number]:focus,
.simple_form input[type=password],
    .simple_form input[type=password]:active,
    .simple_form input[type=password]:focus,
.simple_form input[type=text],
    .simple_form input[type=text]:active,
    .simple_form input[type=text]:focus,
.table td,
    .table th,
    .table.inline-table>tbody>tr:nth-child(odd)>td,
    .table.inline-table>tbody>tr:nth-child(odd)>th,
    .table>tbody>tr:nth-child(odd)>td,
    .table>tbody>tr:nth-child(odd)>th,
    .batch-table__row,
    .batch-table__row:nth-child(2n),
    .batch-table__row:hover,
.column-inline-form label,
.dashboard__counters > div > div, .dashboard__counters > div > a,
.log-entry,
    .log-entry__header,
/* modals */
.actions-modal,
    .actions-modal .status,
    .actions-modal ul li:not(:empty) a,
    .status.light .display-name strong, .status.light .status__content,
.boost-modal,
.confirmation-modal,
.mute-modal,
    .block-modal .setting-toggle__label, .mute-modal .setting-toggle__label,
.report-modal,
    .report-modal__statuses .status__content p,
    .report-modal__comment .setting-toggle__label,
.list-editor,
    .list-editor .drawer__inner,
    .list-editor .drawer__inner.backdrop,
.account__moved-note,
.introduction__pager,
.introduction__text p,
.reactions-bar__item,
/* profile cards */
.card__bar,
    .card>a:active .card__bar,
    .card>a:focus .card__bar,
    .card>a:hover .card__bar,
.directory__card__bar,
    .directory__card__extra,
    .accounts-table__count,
    .directory__card__img,
/* public pages */
#new_user .lead,
.landing .hero-widget__footer,
    .landing .simple_form .user_agreement .label_input > label,
    .landing .hero-widget h4,
    .hero-widget__counter,
    .landing .hero-widget__counter span,
    .directory__tag > a, .directory__tag > div,
.activity-stream .entry,
.landing-page #mastodon-timeline,
    .landing-page #mastodon-timeline p,
    .landing-page__forms,
    .landing-page__information,
    .landing-page li,
    .landing-page p,
    .directory__tag h4 small,
    .directory__tag h4 .fa,
    .landing-page .features-list .features-list__row .text,
    .landing-page .features-list .features-list__row .visual .fa,
    .landing-page__short-description h1,
    .landing-page .separator-or span,
.box-widget,
    .contact-widget,
    .landing-page__information.contact-widget,
.rich-formatting li,
    .rich-formatting p,
.public-layout .public-account-bio .account__header__content,
    .public-layout .public-account-bio .roles,
    .public-layout .public-account-bio__extra,
    .public-layout .public-account-bio,
.public-layout .public-account-header__bar::before,
.account__header__fields dt,
.account__header__fields dd,
.hero-widget__text,
.load-more,
.button.button-secondary,
.simple_form__overlay-area__overlay
{
  background: $classic-base-color;
  color: $primary-text-color;
}
